import React from 'react';

import Layout from '../../components/layout';
import Snippet from '../../components/snippet';
import PageTitle from '../../components/pagetitle';
import ExDocLink from '../../components/exdoclink';
import Warning from '../../components/warning';
import SEO from '../../components/seo';


const IndexPage = () => (
  <Layout>
    <SEO title="Google Cloud Storage Cheet Sheet" />
    <PageTitle>Google Storage Cheet Sheet</PageTitle>

    <p className="lead">A list of gsutil commands to manage your Google Storage buckets.</p>

    <Snippet title="List buckets">gsutil ls</Snippet>
    <Snippet title="List bucket content">
      gsutil ls gs://bucket-name<br/>
      gsutil ls gs://bucket-name/ads # <em>Lists a sub folder</em><br/>
      gsutil ls gs://bucket-name/*.png # <em>Lists files with extension png</em><br/><br/>
      gsutil ls -l gs://bucket-name # <em>List with more object information</em><br/>
      gsutil ls -L gs://bucket-name/object-name # <em>Shows object details</em>
    </Snippet>
    <Snippet title="Show bucket metadata">gsutil ls -L -b gs://bucket-name</Snippet>
    <ExDocLink title="ls command on Google Cloud docs" link="https://cloud.google.com/storage/docs/gsutil/commands/ls" />
    
    <Snippet title="Upload object">
      gsutil cp filename gs://bucket-name/<br/>
      gsutil cp *.pdf gs://bucket-name/ # <em>Upload all files with extension pdf</em><br/>
      gsutil cp picture.jpg gs://bucket-name/pictures # <em>Upload one file to a storage bucket folder</em>
    </Snippet>
    <Snippet title="Download object">
      gsutil cp gs://bucket-name/object-name location<br/>
      gsutil cp gs://bucket-name/*.jpg /local-folder # <em>Downloads all .jpg files</em>
    </Snippet>
    <Snippet title="Copy object">
      gsutil cp gs://from-bucket/object-name gs://to-bucket/object-name
    </Snippet>
    <ExDocLink title="cp command on Google Cloud docs" link="https://cloud.google.com/storage/docs/gsutil/commands/cp" />
    
    <Snippet title="Rename object">
      gsutil mv gs://bucket-name/object-name gs://bucket-name/new-object-name
    </Snippet>
    <Snippet title="Move object">
      gsutil mv gs://bucket-name/object-name gs://other-bucket/object-name
    </Snippet>
    <ExDocLink title="mv command on Google Cloud docs" link="https://cloud.google.com/storage/docs/gsutil/commands/mv" />
    
    <Snippet title="Delete object">
      gsutil rm gs://bucket-name/object-name<br />
      gsutil -m rm gs://bucket-name/*.txt # <em>deletes all .txt files in the bucket</em><br />
      gsutil -m rm gs://bucket-name/** # <em>delete all objects in bucket</em>
    </Snippet>
    <Snippet title="Delete bucket">
      gsutil rm -r gs://bucket-name # <em>remove the bucket and its content</em>
    </Snippet>
    <Warning>
      Deleting objects and buckets cannot be undone.
    </Warning>
    <ExDocLink title="rm command on Google Cloud docs" link="https://cloud.google.com/storage/docs/gsutil/commands/rm" />
    <Snippet title="Make object public">
      gsutil acl ch -u AllUsers:R gs://bucket-name/object-name
    </Snippet>
    <Snippet title="Make bucket public">
      gsutil iam ch allUsers:objectViewer gs://bucket-name
    </Snippet>
  </Layout>
)

export default IndexPage